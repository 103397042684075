import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Visible } from "react-grid-system";
import React, { FunctionComponent, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { chunk as _chunk } from "lodash";
import { useIntl } from "react-intl";
import { graphql, Link } from "gatsby";
import { FORMSPREE_ADDRESS } from "../config";

// @ts-ignore
import AniLink from "gatsby-plugin-transition-link/AniLink";

import { Layout, SocialNav } from "../components";
import Preview from "../components/common/Preview";

import styles from "../styles/blog";

interface BlogPageProps extends RouteComponentProps {
  data: {
    allPrismicPost: {
      nodes: Array<{
        uid: string;
        tags: string[];
        data: {
          date: string;
          intro: string;
          title: string;
          preview_image: { alt: string; url: string };
        };
      }>;
    };
    background: {
      publicURL: string;
    };
    prismicBlogMainPage: {
      data: {
        title?: string;
        description?: string;
        keywords: string;
        canonical_url?: {
          url: string;
        };
        image?: {
          alt: string;
          url: string;
        };
      };
    };
  };
}

const BlogPage: FunctionComponent<BlogPageProps> = ({ data }) => {
  const { locale, formatMessage } = useIntl();

  const [posts, setPosts] = useState(data.allPrismicPost.nodes);
  const [currChunk, setCurrChunk] = useState<number>(0);
  const [currTag, setCurrTag] = useState<string | null>(null);

  const {
    Padding,
    BlogPageTitle,
    CategoriesTitle,
    CategoriesList,
    CategorySubtitle,
    SubscribeTo,
    SubscribeToInput,
    SubscribeToInfo,
    SubscribeToButton,
    Post,
    BlogTitle,
    BlogDivider,
    BlogSubtitle,
    BlogBottomRow,
    BlogDate,
    BlogReadMore,
    LoadMoreButton
  } = styles;

  const formatDate = (date: string) =>
    new Date(date).toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric"
    });

  const tags: string[] = [];
  data.allPrismicPost.nodes.map(label => {
    label.tags.map(tag => {
      if (tags.indexOf(tag) === -1) {
        tags.push(tag);
      }
    });
  });

  const handleSelectTag = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    tag: string | null
  ) => {
    e.preventDefault();

    setCurrTag(tag);
    if (tag !== null) {
      setPosts(
        data.allPrismicPost.nodes.filter(post => post.tags.includes(tag))
      );
    } else {
      setPosts(data.allPrismicPost.nodes);
    }
  };

  const postsChunks = _chunk(posts, 9);
  return (
    <Layout
      metaTitle={
        data.prismicBlogMainPage.data.title ||
        formatMessage({ id: "pages.blog.meta-title" })
      }
      metaDescription={
        data.prismicBlogMainPage.data.description ||
        formatMessage({ id: "pages.blog.meta-description" })
      }
      metaKeywords={
        data.prismicBlogMainPage.data.keywords ||
        formatMessage({ id: "pages.blog.meta-keywords" })
      }
      metaImage={data.prismicBlogMainPage.data.image}
      metaURL={data.prismicBlogMainPage.data.canonical_url?.url}
    >
      <Padding
        style={{
          backgroundPosition:
            "right -500px top -500px, left -500px bottom -500px",
          backgroundImage: `url(${data.background.publicURL}), url(${data.background.publicURL})`,
          backgroundSize: "100%",
          backgroundRepeat: "no-repeat"
        }}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <BlogPageTitle>{formatMessage({ id: "blog" })}</BlogPageTitle>
            </Col>
          </Row>
          <Row>
            <Visible lg xl>
              <Col lg={3}>
                <CategoriesTitle>
                  {formatMessage({ id: "categories" })}
                </CategoriesTitle>
                <CategoriesList>
                  <li>
                    <a
                      onClick={e => handleSelectTag(e, null)}
                      style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                      <CategorySubtitle
                        style={{
                          fontWeight: currTag === null ? "bold" : "normal"
                        }}
                      >
                        {formatMessage({ id: "all" })}
                      </CategorySubtitle>
                    </a>
                  </li>
                  {tags.sort( (a ,b) => a > b ? 1 : -1).
                    map((tag, index) => (
                    <li key={`tag#${index}`}>
                      <a
                        onClick={e => handleSelectTag(e, tag)}
                        style={{ textDecoration: "none", cursor: "pointer" }}
                      >
                        <CategorySubtitle
                          style={{
                            fontWeight: tag === currTag ? "bold" : "normal"
                          }}
                        >
                          {tag}
                        </CategorySubtitle>
                      </a>
                    </li>
                  ))}
                </CategoriesList>
                <SocialNav />
                <form
                  className="news-letter-form"
                  target="_blank"
                  action="https://crm.zoho.eu/crm/WebToLeadForm"
                  name="WebToLeads6990000000066159"
                  method="POST"
                >
                  <label className="text-info-s">Subscreva a nossa Newsletter!
                  </label>
                  <input
                    type="text"
                    style={{ display: "none" }}
                    name="xnQsjsdp"
                    defaultValue="1149a5b8633fa9280ae72dc67e0c48176c999898f1c8765513cec8fa589e14ee"
                  />
                  <input type="hidden" name="zc_gad" id="zc_gad" />
                  <input
                          type="text"
                          style={{ display: "none" }}
                          name="xmIwtLD"
                          defaultValue="5e0fa7a9a05296632fa30b05766ad217b2d19a8bb17865740666a25be05875d1"
                    />
                    <input
                          type="text"
                          style={{ display: "none" }}
                          name="actionType"
                          defaultValue="TGVhZHM="
                        />
                    <input
                          type="text"
                          style={{ display: "none" }}
                          name="returnURL"
                          defaultValue="https://www.decunify.com"
                        />
                    <input
                          type="text"
                          style={{ display: "none" }}
                          name="Lead Source"
                          defaultValue="Formulário Web"
                        />
                    <input
                          type="checkbox"
                          style={{ display: "none" }}
                          name="LEADCF101"
                          defaultChecked="checked"
                        />
                    <label
                          id="labelForDataSource"
                          htmlFor="data-source"
                          className="visuallyhidden"
                          style={{ display: "none " }}
                        />
                    <input
                          aria-labelledby="labelForDataSource"
                          type="hidden"
                          value="Decunify Contact Form"
                          name="data-source"
                        />
                    <label
                      id="labelForName"
                      htmlFor="nameform"
                      className="visuallyhidden"
                      style={{ display: "none" }}
                    >
                       placeholder={formatMessage({ id: "name" })}
                    </label>
                    <input
                      className="input-s"
                      style={{ padding: "6px 10px" }}
                      placeholder={formatMessage({ id: "name" })}
                      aria-labelledby="labelForName"
                      type="text"
                      name="Last Name"
                      id="nameform"
                    />
                    <label
                      id="labelForCompany"
                      htmlFor="companyform"
                      className="visuallyhidden"
                      style={{ display: "none" }}
                    >
                      {formatMessage({ id: "company" })}
                    </label>
                    <input
                      className="input-s"
                      aria-labelledby="labelForCompany"
                      style={{ padding: "6px 10px" }}
                      placeholder={formatMessage({ id: "company" })}
                      type="text"
                      name="Company"
                      id="companyform"
                    />
                    <label
                      id="labelForEmail"
                      htmlFor="emailform"
                      className="visuallyhidden"
                      style={{ display: "none" }}
                    >
                      {formatMessage({ id: "email" })}
                    </label>
                    <input
                      className="input-s"
                      aria-labelledby="labelForEmail"
                      style={{ padding: "6px 10px" }}
                      placeholder="example@email.com"
                      type="email"
                      name="Email"
                      id="emailform"
                    />
                    <label className="info-s">
                    {formatMessage({ id: "footer-subscribe-to-terms" })}
                    </label>
                    <button type="submit" className="button-s" style={{
                      width:100
                    }}>
                      {formatMessage({ id: "send" })}
                    </button>
              </form>
              </Col>
            </Visible>
            <Col xs={12} lg={9}>
              <Row style={{justifyContent: "space-evenly"}}>
                {postsChunks.map(
                  (chunk, chunkIndex) =>
                    chunkIndex <= currChunk &&
                    chunk.map((post, index) => (
                      <Col
                        xs={12}
                        md={4}
                        key={`post-${index}`}
                        style={{ marginBottom: "20px", minWidth: "290px" }}
                      >
                        <Post>
                          <div className="blog-post-image">
                            {post.data.preview_image && post.data.preview_image.url ? (
                              <img
                                src={post.data.preview_image.url}
                                alt={post.data.preview_image.alt}
                                className="post-image"
                              />
                            ) : null}
                            <div className={`rec${index % 3}`} />
                          </div>
                          <Link className="blog-title-link" to={`/${locale}/blog/posts/${post.uid}`}>
                            <BlogTitle>{post.data.title.slice(0, 52)}</BlogTitle>
                          </Link>
                          <div style={{ width: "100%" }}>
                            <BlogDivider />
                            <Link className="blog-title-link" to={`/${locale}/blog/posts/${post.uid}`}>
                              <BlogSubtitle>{post.data.intro}</BlogSubtitle>
                            </Link>
                            <BlogBottomRow>
                              <BlogDate>{formatDate(post.data.date)}</BlogDate>
                              <AniLink
                                cover
                                bg="#f38f1d"
                                duration={1}
                                direction="top"
                                style={{ textDecoration: "none" }}
                                to={`/${locale}/blog/posts/${post.uid}`}
                              >
                                <BlogReadMore>
                                  <span
                                    style={{
                                      marginRight: "10px",
                                      textTransform: "uppercase"
                                    }}
                                  >
                                    {formatMessage({ id: "read-more" })}
                                  </span>
                                  <FontAwesomeIcon
                                    size="sm"
                                    color="#f38f1d"
                                    icon={faChevronRight}
                                  />
                                </BlogReadMore>
                              </AniLink>
                            </BlogBottomRow>
                          </div>
                        </Post>
                      </Col>
                    ))
                )}
                <Col xs={12} style={{ textAlign: "center" }}>
                  {postsChunks.length > currChunk + 1 && (
                    <LoadMoreButton
                      type="button"
                      onClick={() => setCurrChunk(currChunk + 1)}
                    >
                      {formatMessage({ id: "load-more-posts" })}
                    </LoadMoreButton>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Visible xs sm md>
              <Col xs={12} style={{ marginTop: "100px" }}>
                <CategoriesTitle>
                  {formatMessage({ id: "categories" })}
                </CategoriesTitle>
                <CategoriesList>
                  {tags.map((tag, index) => (
                    <li key={`tag#${index}`}>
                      <a
                        onClick={e => {
                          handleSelectTag(e, tag)
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth"
                          })
                        }}
                        style={{ textDecoration: "none", cursor: "pointer" }}
                      >
                        <CategorySubtitle
                          style={{
                            fontWeight: tag === currTag ? "bold" : "normal"
                          }}
                        >
                          {tag}
                        </CategorySubtitle>
                      </a>
                    </li>
                  ))}
                </CategoriesList>
                <SocialNav />
              </Col>
            </Visible>
          </Row>
        </Container>
      </Padding>
    </Layout>
  );
};

export const query = graphql`
  # query($lang: String!) {
  # allPrismicPost(filter: { lang: { eq: $lang } }) {
  query {
    allPrismicPost(filter: {lang: {eq: "pt-pt"}}, sort: {fields: data___date, order:DESC}) {
      nodes {
        uid
        tags
        data {
          preview_image {
            alt
            url
          }
          date
          intro
          title
        }
      }
    }
    background: file(
      sourceInstanceName: { eq: "static" }
      relativePath: { eq: "images/Background-single-transparent.png" }
    ) {
      publicURL
    }
    # mainPage: prismicBlogMainPage(lang: { eq: $lang }) {
    prismicBlogMainPage(lang: { eq: "pt-pt" }) {
      data {
        title
        description
        keywords
        image {
          url
          alt
        }
        canonical_url {
          url
        }
      }
    }
  }
`;

export default Preview(BlogPage);
