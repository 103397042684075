import styled from "@emotion/styled";

const Padding = styled.div`
  padding: 31px 0 100px 0;
`;

const BlogPageTitle = styled.h1`
  font-family: Roboto;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 1.75px;
  text-align: center;
  color: #f38f1d;
  text-transform: uppercase;
  margin-bottom: 128px;
`;

const CategoriesTitle = styled.h4`
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-top: 0;
  margin-bottom: 32px;
`;

const CategoriesList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  margin-bottom: 77px;
  li {
    margin-bottom: 17px;
  }
  li:last-child {
    margin-bottom: 0;
  }
`;

const CategorySubtitle = styled.h5`
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;

const SubscribeTo = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-top: 46px;
  margin-bottom: 9px;
  display: block;
`;

const SubscribeToInput = styled.input`
  width: 100%;
  outline: none;
  display: block;
  max-width: 185px;
  font-size: 14px;
  line-height: 1.2;
  font-weight: bold;
  font-style: normal;
  border-radius: 3px;
  margin-bottom: 3px;
  font-family: Roboto;
  font-stretch: normal;
  box-sizing: border-box;
  letter-spacing: normal;
  background-color: #ffffff;
  border: 2px solid #777777;
  padding: 6px 11px 9px 11px;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ::placeholder {
    color: #d4cfcf;
  }
`;

const SubscribeToInfo = styled.span`
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  display: block;
  margin-top: 4px;
  margin-bottom: 5px;
`;

const SubscribeToButton = styled.button`
  border: none;
  outline: none;
  color: #000000;
  cursor: pointer;
  font-size: 14px;
  min-height: 32px;
  line-height: 1.2;
  font-weight: bold;
  text-align: center;
  font-style: normal;
  font-family: Roboto;
  font-stretch: normal;
  letter-spacing: normal;
  border: 2px solid #777777;
  background-color: #ffffff;
  transition: all 0.15s ease-in-out;
`;

const Post = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
`;

const BlogTitle = styled.h2`
  font-family: Roboto;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 5px;
  display: flex;
  margin-top: 32px;
  height: 78px;
  overflow: hidden;
`;

const BlogDivider = styled.hr`
  width: 100%;
  height: 2px;
  margin-top: 0;
  margin-bottom: 24px;
  background-color: #979797;
`;

const BlogSubtitle = styled.h3`
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  color: #000000;
  margin-top: 0;
  margin-bottom: 10px;
  height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

const BlogBottomRow = styled.div`
  padding-top:20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const BlogDate = styled.span`
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.08px;
  text-transform: uppercase;
  color: #a7a9ac;
`;

const BlogReadMore = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000000;
`;

const LoadMoreButton = styled.button`
  cursor: pointer;
  outline: none;
  min-height: 45px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.5px;
  text-align: center;
  border: solid 1px #f38f1d;
  background-color: #ffffff;
  color: #000000;
  padding: 0 17px;
  text-transform: uppercase;
  transition: all 0.15s ease-in-out;
`;

const styles = {
  Padding,
  BlogPageTitle,
  CategoriesTitle,
  CategoriesList,
  CategorySubtitle,
  SubscribeTo,
  SubscribeToInput,
  SubscribeToInfo,
  SubscribeToButton,
  Post,
  BlogTitle,
  BlogDivider,
  BlogSubtitle,
  BlogBottomRow,
  BlogDate,
  BlogReadMore,
  LoadMoreButton
};

export default styles;
